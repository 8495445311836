$(document).ready(function() {

	/************************************************/
	/**** PRICING OVERVIEW ORDER VOLUME SWITCH ****/
	/************************************************/
	if (document.body.contains(document.querySelector('.pricing-overview-2'))) {
		let pricingOverview = document.querySelector('.pricing-overview-2');

		// Select element
		const selectElement = pricingOverview.querySelector("#number-picker");

		// Custom dropdown UI (proxy for select element on desktop)
		const customDropdown = pricingOverview.querySelector('#custom-dropdown');
		const customDropdownSelected = pricingOverview.querySelector('#custom-dropdown-selected');
		const customDropdownOptions = pricingOverview.querySelector('#custom-dropdown-options');
		const customDropdownOptionsArray = customDropdownOptions.querySelectorAll('.custom-dropdown__option');

		customDropdownSelected.addEventListener('click', function() {
			customDropdownOptions.style.display = customDropdownOptions.style.display === 'block' ? 'none' : 'block';
		});

		customDropdownOptionsArray.forEach(function(option) {
			option.addEventListener('click', function() {
				const value = option.getAttribute('data-value');
				selectElement.value = value;
				customDropdownSelected.innerText = option.innerText;
				customDropdownOptions.style.display = 'none';

				// Trigger the change event on the selectElement
				const event = new Event('change');
				selectElement.dispatchEvent(event);
			});
		});

		document.addEventListener('click', function(event) {
			if (!customDropdown.contains(event.target)) {
				customDropdownOptions.style.display = 'none';
			}
		});

		// Plan column header elements
		const classicPriceFigure = pricingOverview.querySelector("#js-monthly-price");
		const classicPriceContainer = pricingOverview.querySelector("#js-classic-price-container");
		const classicPricePlaceholder = pricingOverview.querySelector("#js-classic-price-placeholder");

		// Orders <-> prices map
		const priceMapping = {
			initial: '$199',
			1000: '$199',
			1500: '$249',
			2000: '$299',
			2500: '$349',
			3000: '$399',
			3500: '$449',
			4000: '$499',
			5000: '$599',
			6000: '$649',
			7000: '$699',
			8000: '$749',
			9000: '$799',
			10000: 'n/a'
		};

		// When select changes
		selectElement.addEventListener('change', function() {
			const selectedValue = selectElement.value;

			// Update price
			if (selectedValue == 10000) {
				classicPriceContainer.classList.add('hidden');
				classicPricePlaceholder.classList.remove('hidden');
			} else {
				classicPriceContainer.classList.remove('hidden');
				classicPricePlaceholder.classList.add('hidden');
				classicPriceFigure.textContent = priceMapping[selectedValue] || '';
			}

			// Update custom dropdown to reflect the selected value (in case the change to selected value originated from mobile select)
			customDropdownOptionsArray.forEach(function(option) {
				if (option.getAttribute('data-value') === selectedValue) {
					customDropdownSelected.innerText = option.innerText;
				}
			});
		});
	}


	/************************************************/
	/**** PRICING PLANS OVERVIEW ****/
	/************************************************/
	document.querySelectorAll('.pricing-overview').forEach(function(pricingOverview) {
		const expandFeaturesTriggers = pricingOverview.querySelectorAll('.pricing-overview__features-accordion-trigger');

		expandFeaturesTriggers.forEach(function(trigger) {
			trigger.addEventListener('click', function() {
				const targetId = trigger.getAttribute('data-target');
				const featuresList = pricingOverview.querySelector('#' + targetId);

				trigger.classList.toggle('expanded');
				featuresList.classList.toggle('expanded');
			});
		});
	});


	/************************************************/
	/**** COMPARISON TABLE ****/
	/************************************************/

	//// tab click function on pricing comparison table
	$('.compare-tabs-selector').click(function() {
		event.preventDefault(); // Prevent default link behavior

		// Remove 'is-active' class from all siblings
		$('.compare-tabs-selector').removeClass('is-active');

		// Add 'is-active' class to the clicked element
		$(this).addClass('is-active');

		// get target panel ID from url
		var panelId = $(this).attr('href');

		// show panel
		$(panelId).show();

		// hide other panels
		$('.js-pricing-compare-panel').not(panelId).hide();

	})
	

	$('tr.heading').click(function() {
		if ($(this).hasClass('active')) {
			$(this).removeClass('active')
		} else {
			$(this).addClass('active')
		}
		$(this)
		.closest('tbody')
		.find('tr.list')
		.toggle()
	})

})
