/*
* Find any <a> link with href of '#book-a-demo' and replace with demo page url
*/
$(document).ready(function() {

	var links = document.querySelectorAll("a");

	for (var i = 0; i < links.length; i++) {
		var link = links[i].getAttribute("href");

		if(link.includes("#book-a-demo")) {
			links[i].setAttribute("href", "https://loyaltylion.com/demo");
			console.log("#book-a-demo link found and modified");
		}
	}

});
